import React, { useContext, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// Context
import UserContext from '../../state-management/context/UserContext';
import {
    INCREMENT_INDEX,
    /* SET_LANGUAGE, */
    SET_START_TIME,
    SET_SIDEBAR,
} from '../../state-management/actions/actionTypes';

// Components
import Animation from '../animations/Animation';
import Button from '../shared/Button';
import api from "../../consts/api";
import moment from "moment";
/* import i18next from "i18next"; */
import Icon from '../shared/Icon';
import stepIndex from '../../consts/stepIndex';
import logoChecker from '../shared/logoChecker';

const LandingPage = () => {
    // Context variables
    const [userState, userDispatch] = useContext(UserContext);
    // Translation hook
    const { t } = useTranslation();

    // Check the type of device the user has
    const isMobileDevice = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    const logWindowSize = () => {
        api.post('logging/window-size', {
            isMobileDevice: isMobileDevice(),
            uuid: userState.uuid,
            width: window.innerWidth,
            height: window.innerHeight,
            browser: navigator.userAgent
        });
    }

    useEffect(() => {
        logWindowSize();
        userDispatch({ type: SET_START_TIME, startTime: moment() });
    }, []);

    useEffect(() => {
        /* if (process.env.GCLOUD_PROJECT !== 'goverify-fb-production') {
            
        } */
/*         if (userState.user.isoCountryOfHire === 'DEU' || userState.user.isoCountryOfHire === 'AUT') {
            i18next.changeLanguage('de');
            sessionStorage.setItem('language', 'de');
            userDispatch({ type: SET_LANGUAGE, currentLanguage: 'de' });
        } else if (userState.user.isoCountryOfHire === 'NLD') {
            i18next.changeLanguage('nl');
            sessionStorage.setItem('language', 'nl');
            userDispatch({ type: SET_LANGUAGE, currentLanguage: 'nl' });
        } else if (userState.user.isoCountryOfHire === 'POL') {
            i18next.changeLanguage('pl');
            sessionStorage.setItem('language', 'pl');
            userDispatch({ type: SET_LANGUAGE, currentLanguage: 'pl' });
        } /* else if (userState.user.isoCountryOfHire === 'ESP') {
            i18next.changeLanguage('es');
            sessionStorage.setItem('language', 'es');
            userDispatch({ type: SET_LANGUAGE, currentLanguage: 'es' });
        } else if (userState.user.isoCountryOfHire === 'FRA') {
            console.log("Switching to FRA")
            i18next.changeLanguage('fr');
            sessionStorage.setItem('language', 'fr');
            userDispatch({ type: SET_LANGUAGE, currentLanguage: 'fr' });
        } */
    }, []);

    // Specific Help/language icon
    const renderHelpIcon = () => {
        //If you're on the landing page but not using a mobile device, show the help icon
        if (logoChecker()) {
            if (userState.onboardingIndex === stepIndex.LANDING_PAGE) {
                if (!isMobileDevice()) {
                    return (
                        <Icon
                            icon="gov__help-icon"
                            label="Help icon"
                            onClickHandler={() => window.open(`${window.location.origin}/#/help-section`)}
                        />
                    );
                } else {
                    return (
                        <div
                            className="gov__language-nav"
                            role="button"
                            aria-pressed="false"
                            style={{ marginLeft: 'auto' }}
                            onClick={() => userDispatch({ type: SET_SIDEBAR, sidebarStatus: 'visible' })}
                        >
                            <Icon
                                icon={`gov__language-icon ${userState.currentLanguage}`}
                                label="Language icon"
                            />
                            <span>{userState.currentLanguage.toUpperCase()}</span>
                        </div>
                    );
                }
            }
        }
    };

    return (
        <div className="gov__landing-page-container">
            <Animation animationName="LANDING_PAGE_ANIMATION" elementName="gov__landing-page-icon" />
            <div className="gov__landing-page-information">
                <h1>
                    <Trans i18nKey="landing-page.greeting" values={{ firstName: userState.user.firstName }} /><br />
                    <Trans i18nKey="landing-page.welcome" components={{ wrap: <span className="gov__rtw-message" /> }} />
                </h1>

                {/* If on a desktop, we should display the path to the QR code and block the new hire from progressing */}
                {
                    isMobileDevice()
                        ? (
                            <p><Trans i18nKey="landing-page.description" values={{ countryOfHire: userState.user.countryOfHire }} components={{ bold: <span /> }} /></p>
                        ) : (
                            <div className="gov__desktop-subtext">
                                <p><Trans i18nKey="landing-page.incompatible-device" components={{ bold: <span /> }} /></p>
                                <p><Trans i18nKey="landing-page.incompatible-device-instructions" components={{ bold: <span /> }} /></p>
                            </div>
                        )
                }

                <div className="gov__landing-page-buttons">
                    <div>
                        {
                            isMobileDevice() ? (
                                <Button onClickHandler={() => userDispatch({ type: INCREMENT_INDEX })} text={t('landing-page.launch')} />
                            ) : (
                                <Link to="qr-code"><Button text={t('landing-page.qr-code.generate-qr-code')} />{renderHelpIcon()}</Link>
                            )

                        }
                    </div>

                    {
                        isMobileDevice() && (
                            <><div className="gov__report">
                                <Link className="gov__link" to="incorrect-data">
                                    <Trans i18nKey="landing-page.not-user" />
                                </Link>
                                {renderHelpIcon()}
                            </div></>
                        )
                    }
                </div>
            </div>
        </div>
    );
};

export default LandingPage;
