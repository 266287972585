import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL || 'http://localhost:3001/api',
    withCredentials: true,
});

api.interceptors.response.use((response) => {
    return Promise.resolve(response);
},
    (error) => {
        // Handle the error response
        if (error.response && error.response.status === 503) {
            window.location.href = '/#/offline';
            return;
        }
        return Promise.reject(error);
    });

export default api;
